const validateForm = (form, func) => {
    form.on("submit", function (e) {
        e.preventDefault();
    });
    $.validator.addMethod("goodMessage", function (value, element) {
        return this.optional(element) || /^[\sаА-яЯіІєЄїЇґҐa-zA-Z0-9._-]{2,100}$/i.test(value);
    }, "Please enter correct");

    $.validator.addMethod("goodEmail", function (value, element) {
        return this.optional(element) || /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,62}$/i.test(value);
    }, "Please enter correct email");


    form.validate({
        rules: {
            email: {
                required: true,
                goodEmail: true,
                email: true
            },
            message: {
                required: true,
                goodMessage: true,
            }
        },
        messages: {
            email: {
                required: "This field is required",
                email: "Please enter correct email"
            },
            message: {
                required: "This field is required",
                minlength: "First name can't be shorter than 2 characters",
                maxLength: "First name can't be longer than 100 characters "
            },

        },
        submitHandler: function () {
            func();
            form[0].reset();
        }
    });
};

function ajaxSend(form) {
    let formData = form.serialize();
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        data: formData,
        method: 'POST',
        success: function () {
            console.log('success ajax');
            changeContent()

        },
        error: function (error) {
            console.log('error ajax');
            changeContent()

        },
        complete: function (){

        }
    });
}



function changeContent(){
    $('.contact__form').addClass('hide')
    $('.contact__form-message').addClass('show')
    $(document).on('click', '.contact__form-message button', function (){
        $('.contact__form-message').removeClass('show')
        $('.contact__form').removeClass('hide')
    })
}

function addAnchor(){
    let anchorsTerm = $('.policy__item h2');
    anchorsTerm.map((index, item) => {
        console.log(index, item)
        let textItem = $(item).text();
        let anchor = textItem.replace('(','').replace(')','').toLowerCase().replace(/\s+/g, '-');
        $(item).attr('id', anchor);
        $('.policy__anchor ul').append(`<li><a href="#${anchor}">${textItem}</a></li>`)
    })


    let url = window.location.origin + window.location.pathname
    $(".policy__anchor li:first-child a").addClass('active')
    $(".policy__anchor a").on("click", function(e){
        e.preventDefault();
        $(".policy__anchor a").removeClass('active')
        $(this).addClass('active')
        var anchor = $(this).attr('href');
        let newLink = url + anchor
        window.history.replaceState({}, document.title, newLink);
        $('html, body').stop().animate({
            scrollTop: $(anchor).offset().top - 110
        },300);
    });
}


function changeSection(){

}
function changeHeader(){
    let scrolled = $(window).scrollTop();
    console.log(123, scrolled)
    if (scrolled > 0) {
        $('.header').addClass('scroll')
    } else {
        $('.header').removeClass('scroll')
    }

}



$(document).ready(function(){
    let contactForm = $('.contact__form');
    validateForm(contactForm, function () {
        ajaxSend(contactForm, '/wp-admin/admin-ajax.php')
    });

    addAnchor();
    changeHeader();

    changeSection();
    AOS.init({
        disable:'mobile',
        offset: 400,
    });



});

$(window).load(function(){

});

$(window).resize(function(){

});
$(window).scroll(function () {
    changeHeader()
});
